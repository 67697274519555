import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./main.css";
import axios from '@/services/axios';

// Set API URL
// window.API_URL = "https://lmp-api.vdisain.dev/api";
window.STORAGE_URL = "https://lmp-api.vdisain.dev/storage";

if (window.location.hostname.includes("localhost")) {
  window.API_URL = "https://api.lmp.test/api";
  // window.STORAGE_URL = "https://api.lmp.test/storage";
  axios.defaults.headers.common['ngrok-skip-browser-warning'] = '11111';
}

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
