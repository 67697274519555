import './Loading.css';
import logo from '../../assets/logo_black.png';
import loader from './Loading.svg';

function Loading () {
  return <div className="loading-splash">
    <div>
      <img className="logo" src={logo} alt="Learn Med Pro" />
      <img className="loader" src={loader} alt="" />
    </div>
  </div>
}

export default Loading;