import { createContext, Suspense, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FieldLanguageContext } from "./context";
import "./i18n";
import { AuthProvider } from '@/hooks/useAuth';
import { ErrorBagProvider } from '@/hooks/useErrorBag';
import Loading from "./components/Loading/Loading";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";

const router = createBrowserRouter(routes, {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_startTransition: true,
    v7_skipActionStatusRevalidation: true,
  },
});

export const ModalContext = createContext({});
export const IconModalContext = createContext(null);
export const ParentIdContext = createContext(null);

function App() {
  const [modal, setModal] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [fieldLanguage, setFieldLanguage] = useState('et');

  return (
    <Suspense fallback={<Loading />}>
      <AuthProvider>
        <ErrorBagProvider>
          <ModalContext.Provider value={{ modal, setModal }}>
            <ParentIdContext.Provider value={{ parentId, setParentId }}>
              <FieldLanguageContext.Provider value={{ fieldLanguage, setFieldLanguage }}>
                <RouterProvider router={router} />

                <ToastContainer autoClose={3000} />
              </FieldLanguageContext.Provider>
            </ParentIdContext.Provider>
          </ModalContext.Provider>
        </ErrorBagProvider>
      </AuthProvider>
    </Suspense>
  );
}

export default App;
