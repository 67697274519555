import { AxiosError, CanceledError } from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { useAuth } from './useAuth';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ErrorBagContext = createContext(null);

export const useErrorBag = () => {
  return useContext(ErrorBagContext);
};

export const ErrorBagProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});

  const { logout } = useAuth();
  const { t } = useTranslation();

  const clear = () => {
    setErrorMessage(null);
    setErrors([]);
  }

  const clearError = (key) => {
    key = key.replace(/\./g, '_');

    if (errors[key]) {
      delete (errors[key]);
    }
  };

  const fill = (data) => {
    Object.keys(data).forEach((key) => {
      setError(key, data[key]);
    });
  };

  const getError = (key) => errors?.[key.replace(/\./g, '_')] || null;

  const handleError = (err) => {
    if (err instanceof CanceledError) {
      // Request was canceled. Do nothing
      return;
    }

    setErrors(null);
    console.error('%cERROR:', 'color: tomato; font-size: 1rem; font-weight: bold;', err);

    if (err instanceof AxiosError) {
      let message = err.response?.data?.message || err.message;

      if (err.response?.status === 401) {
        // Unauthorized - Log out the user
        // message = t('error.unauthorized');
        logout(true);
      } else if (err.response?.status === 413) {
        // Payload too large
        message = t('error.payload_too_large');
        toast.error(t('error.payload_too_large'));
      } else if (err.response?.status === 422) {
        // Validation error
        fill(err.response.data?.errors || {});
      }

      setErrorMessage(message);
      toast.error(message);

      return;
    }

    setErrorMessage(err.message);
  }

  const hasError = (key) => errors && Object.hasOwn(errors, key.replace(/\./g, '_')) && !!errors[key.replace(/\./g, '_')];
  const hasErrors = () => errors && Object.keys(errors).length;

  const setError = (key, value) => {
    key = key.replace(/\./g, '_');
    setErrors((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <ErrorBagContext.Provider value={{
      errorMessage,
      errors,

      clear,
      clearError,
      getError,
      handleError,
      hasError,
      hasErrors,
      setError,
    }}>
      {children}
    </ErrorBagContext.Provider>
  );
};
